export const faqData = [
  {
    tabTitle: 'Senders',
    iconName: 'mdi-account',
    tabData: [
      {
        accordianTitle: 'What is a Sender?',
        accordianText:
          'A Sender is the resource used to send the SMS. This could be a long code, short code or a messaging service.',
      },
      {
        accordianTitle: 'What is a Messaging Service?',
        accordianText:
          'You can think of a Messaging Service a higher-level �bundling� of messaging functionality around a common set of senders, features, and configuration. The same settings and feature configuration applies to all of the senders (long code numbers, short codes, toll-free numbers, etc.) in the Messaging Services pool.',
      },
      {
        accordianTitle: 'What is a short code?',
        accordianText:
          'A short code is a special 5 or 6 digit telephone number thats shorter than a full phone number.Short codes are used to send and receive SMS and MMS messages to and from mobile phones.Twilio currently offers short codes in the US, Canada, and the UK.',
      },
      {
        accordianTitle: 'What is a long code?',
        accordianText:
          'A long code number is a standard phone number used to send and receive voice calls and SMS messages. Phone numbers are typically called �long codes� (10-digit numbers in many countries) when comparing them with SMS short codes (5-6 digit numbers).',
      },
      {
        accordianTitle: 'How is a sender assigned?',
        accordianText:
          'Sender resources are assigned by the CSI IT department. Please contact them at (208) 732-6311 or helpdesk@csi.edu',
      },
    ],
  },
  {
    tabTitle: 'Contacts',
    iconName: 'mdi-clipboard-outline',
    tabData: [
      {
        accordianTitle: 'What is a contact?',
        accordianText:
          'A contact is a person that can engage in a sms conversation. This would mean recieving and responding to sms from CSI.',
      },
      {
        accordianTitle: 'Contact Subscription Status',
        accordianText:
          'A contact can have one of three statuses. New - A contact added to the CRM with actively opting in to recieve SMS from CSI. Suhscribed - A contact that has opted in to recieve SMS from CSI. Unsubscribed - A contact that has opted out of recieving SMS from CSI. A contact can unsubscribe and res-subscribe from senders. If a contact unsubscribes from a certain senders from CSI, he can still recieve SMS from other senders from CSI.',
      },
      {
        accordianTitle: 'Importing Contacts',
        accordianText:
          'The format to import contacts is CSV. Columns needed to import are FirstName, LastName, CellPhone, StudentID. StudentID is optional. A group is required to import contacts into. Duplicate cell numbers will update the First Name and Last Name if those fields are blank. If the contact is already in the system, the contact will inherit the contact status of the contact when adding to the group. If the contact is not in the system, the contact will have the status of New. ',
      },
      {
        accordianTitle: 'Creating Contacts',
        accordianText:
          'Contacts can be created with the following fields.FirstName, LastName, CellPhone, StudentID. StudentID is optional. A group is required to create a contact. Duplicate cell numbers will update the First Name and Last Name if those fields are blank. If the contact is already in the system, the contact will inherit the contact status of the contact when adding to the group. If the contact is not in the system, the contact will have the status of New.',
      },
      {
        accordianTitle: 'Deleting/Updating Contacts',
        accordianText:
          'Currently, updating and deleting contacts are not allowed. If you need to make these types of changes please contact the CSI IT team.',
      },
    ],
  },
  {
    tabTitle: 'Groups',
    iconName: 'mdi-google-circles',
    tabData: [
      {
        accordianTitle: 'What is a group?',
        accordianText:
          'A group is a method to group similar contacts. For example, CSI Freshman, CSI English Majors.',
      },
      {
        accordianTitle: 'Creating Groups',
        accordianText:
          'Groups require a name and a sender to create. The sender resource will be used to send SMS to contacts. There is no limit to the number of groups you can create. However, they will share the sender resource. If a contact opts out of the sender, they will opt out of all groups using that sender resource.',
      },
      {
        accordianTitle: 'Deleting a Group',
        accordianText:
          'You can delete a group by simply clicking on the trash can icon on the group listing from under My Groups. If you delete a group, you will not be able to see Messaging History or Campaign Insights relating to that group.',
      },
      {
        accordianTitle: 'Updating a Group',
        accordianText:
          'Currently, updating groups is not allowed. If you need to make these types of changes please contact the CSI IT team.',
      },
    ],
  },
  {
    tabTitle: 'Group Memberships',
    iconName: 'mdi-account-multiple',
    tabData: [
      {
        accordianTitle: 'What is group membership?',
        accordianText:
          'Groups can organize contacts to better target SMS campaigns and messages. Contacts MUST belong to a group in order to send those contacts a SMS.',
      },
      {
        accordianTitle: 'Can a contact belong to more than one group?',
        accordianText:
          'Contacts can belong to more than one group. The contacts membership is managed independantly per group. The contact status of one group does not affect the membership and status in other groups.',
      },
      {
        accordianTitle: 'How can send a message to a group or contact?',
        accordianText:
          'You can send a message to contacts by checking the checkbox by the contact and hit the Compose Message button. The Compose Message dialog has the following required fields. Name (this is will help indentify the message in Message History and Message Insights), Message. Media is an optional field. If you want to send a multi media message input the URL of the image to send. The message is restricted to 153 characters. The signature is part of the character count. ',
      },
      {
        accordianTitle:
          'Can I see the SMS history between the group and the contact?',
        accordianText:
          'You can see the smsm history of the contact and group by selecting the clock icon of the selected contact.',
      },
      {
        accordianTitle: 'Can I remove a contact from group?',
        accordianText:
          'You can remove a contact from a group by selecting the trash can icon of the selected contact..',
      },
    ],
  },
  {
    tabTitle: 'Message History',
    iconName: 'mdi-history',
    tabData: [
      {
        accordianTitle: 'What is Message History?',
        accordianText:
          'Message history is the ability to see all the SMS communication between the group and the contact. The SMS history includes messages sent and responses from the contact. The history is filtered by the group selected and the corresponding contact selected.',
      },
      {
        accordianTitle: 'What are message statuses?',
        accordianText:
          'Messages status are the reported statuses when a sms is sent to the contact. You can filter messages by statuses Failed and Delivered. You are also presented with overall status counts of the sms communication bewtween contact and group.',
      },
      {
        accordianTitle: 'Filtering by Message Types',
        accordianText:
          'You can also filter by SMS sent to the contact and SMS recieved by the contact. These are also filtered by group.',
      },
    ],
  },
  {
    tabTitle: 'Message Insights',
    iconName: 'mdi-chart-pie',
    tabData: [
      {
        accordianTitle: 'What are Message Insights?',
        accordianText:
          'Message insights are insights into how your sms message performed. This will include how many sms were sent per campaign, how many were successful delievered and how many failed.',
      },
      {
        accordianTitle: 'What are campaigns? ',
        accordianText:
          'Campaigns are the name you title your message when it is sent.',
      },
      {
        accordianTitle: 'How are campaigns organized?',
        accordianText:
          'Campaigns are oranized by the title of the campaign and the date the campaign was sent.',
      },
    ],
  },
  {
    tabTitle: 'Survey Insights',
    iconName: 'mdi-chart-bar',
    tabData: [
      {
        accordianTitle: 'Survey Insights sample question 1',
        accordianText:
          'Survey Insights sample answer 1',
      },
      {
        accordianTitle: 'Survey Insights sample question 2 ',
        accordianText:
          'Survey Insights sample answer 2',
      },
      {
        accordianTitle: 'How are campaigns organized?',
        accordianText:
          'Campaigns are oranized by the title of the campaign and the date the campaign was sent.',
      },
    ],
  },
  {
    tabTitle: 'Manage Templates',
    iconName: 'mdi-file-document',
    tabData: [
      {
        accordianTitle: 'What are templates?',
        accordianText:
          'Templates are pre-defined message body',
      },
      {
        accordianTitle: 'Where can I use them? ',
        accordianText:
          'While sending messages to group contacts',
      },
      {
        accordianTitle: 'What are placeholders?',
        accordianText:
          'Placeholders are dynamic fields, which will be filled according to user/recipient\'s data',
      },
    ],
  },
]
