
<template>
  <div class="tabWrapper">
    <v-overlay :value="isOverlayVisible">
      <v-progress-circular
        :size="70"
        :width="7"
        color="amber"
        indeterminate
        class="spinner"
      />
    </v-overlay>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="3"
          offset-md="9"
          offset-lg="9"
        >
          <feedback-dialog
            @on-submission-start="showLoader"
            @on-success="onFeedbackSuccess"
            @on-error="hideLoader"
          />
        </v-col>
        <v-col
          cols="12"
          md="12"
        >
          <div class="tabsWrapper">
            <div
              v-for="(tab, tabIndex) in faqDataSet"
              :key="tabIndex"
              class="tab"
              :class="{ 'selected': tabIndex === selectedTabIndex }"
              @click="selectTab(tabIndex)"
            >
              <div class="data">
                <v-icon>{{ tab.iconName }}</v-icon>
                <p>{{ tab.tabTitle }}</p>
              </div>
            </div>
          </div>
          <div class="faqViewerWrapper">
            <div class="faqViewer">
              <h1>Frequently asked questions</h1>
              <h3>on {{ faqDataSet[selectedTabIndex].tabTitle }}</h3>
              <template v-if="!isAccordianVisible">
                <v-expansion-panels
                  v-model="panel"
                >
                  <v-expansion-panel
                    v-for="(item,i) in faqDataSet[selectedTabIndex].tabData"
                    :key="i"
                  >
                    <v-expansion-panel-header>{{ item.accordianTitle }}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      {{ item.accordianText }}
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="snackbar"
    >
      {{ notification }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import AuthService from '@/services/AuthService'
  import { faqData } from '../../../static-data/faq-data'
  const auth = new AuthService()

  export default {
    components: {
      FeedbackDialog: () => import('../components/FeedbackDialog'),
    },
    data: () => ({
      selectedTabIndex: 0,
      isAccordianVisible: false,
      faqDataSet: null,
      panel: [],
      isOverlayVisible: false,
      snackbar: false,
      notification: 'Unknown',
    }),

    created () {
      this.faqDataSet = faqData
      this.selectedTabIndex = 0
    },

    asyncComputed: {
      async user () {
        const user = await auth.getUser()
        return user.profile
      },
    },

    methods: {
      selectTab (tabIndex) {
        this.panel = []
        this.selectedTabIndex = tabIndex
      },

      showLoader () {
        this.isOverlayVisible = true
      },

      hideLoader () {
        this.isOverlayVisible = false
      },

      onFeedbackSuccess () {
        this.isOverlayVisible = false
        this.notification = 'Feedback submitted successfully'
        this.snackbar = true
      },
    },
  }

</script>
<style lang="scss" scoped>
  .tabWrapper {
    padding: 30px 40px ;
    margin-top: -30px;
  }
  .v-overlay.v-overlay--active.theme--dark {
    z-index: 300!important;
  }
  .spinner {
    margin-left: 17rem;
  }
  @media only screen and (max-width: 968px) {
    .spinner {
      margin-left: 0;
    }
  }
  .tabsWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
    .tab {
      cursor: pointer;
      &:hover {
        background-color: rgb(221, 221, 221)!important;
        .data {
        color: rgb(56, 56, 56)!important;
        * {
          color: rgb(61, 61, 61)!important;
        }
      }
      }
      &.selected {
        background-color: rgb(221, 169, 0)!important;
        -webkit-box-shadow: 0 12px 20px -10px rgba(255,152,0,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(255,152,0,.2);
        box-shadow: 0 12px 20px -10px rgba(255,152,0,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(255,152,0,.2);
        .data {
          color: white!important;
          * {
            color: white!important;
          }
        }
      }
      height: 140px;
      background-color: rgba(255, 248, 220, 0);
      margin: 5px;
      border-radius: 6px;
      line-height: 24px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      width: 130px;
      text-align: center;
      color: #3c4858!important;
      -webkit-transition: all .3s;
      transition: all .3s;
      padding: 30px 0px 12px 3px;
      @media only screen and (max-width: 768px) {
        flex-direction: column;
        width: 80%;
        height: 60px;
        padding: 8px 0px 8px 0px;
      }
      .data {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        @media only screen and (max-width: 768px) {
          flex-direction: row;
          justify-content: center;
        }
        color: rgb(58, 58, 58)!important;
        * {
          font-weight: 800;
          font-size: 2rem;
          color: rgb(59, 59, 59)!important;
        }
        p {
          font-size: 15px!important;
          padding: 0px!important;
          margin: 0px!important;
          font-weight: 400!important;
          @media only screen and (max-width: 768px) {
            margin-left: 30px!important;
          }
        }
      }
    }
  }
  .faqViewerWrapper {
    padding-bottom: 6rem;
    margin-top: -30px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 768px) {
      margin-top: -120px;
      padding-bottom: 14rem;
    }
    .faqViewer {
      width: 84%;
      @media only screen and (max-width: 768px) {
        width: 100%;
      }
      background-color: white;
      border-radius: 5px;
      box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.096);
      min-height: 200px;
      padding: 20px 25px 40px 25px;
      h1 {
        font-weight: 200;
      }
      h3 {
        font-weight: 200;
        margin-bottom: 3rem;
      }
    }
  }
</style>
